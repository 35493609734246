

const loginProcedureForLocalStorage = (authToken, validTill, userName) => {
    localStorage.authToken = authToken;
    localStorage.userName = userName;
    localStorage.validTill = validTill;
}

const logoutProcedureForLocalStorage = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("validTill");
}

const isLoggedIn = () => {
    if(localStorage.authToken === null || localStorage.userName === null || localStorage.validTill === null)
        return false;
    
    if(localStorage.validTill > new Date().getTime())
        return true;

    return false;
}

const getUserDataFromLocalStorage = () => {
    return {
        authToken : localStorage.authToken,
        userName : localStorage.userName,
        validTill : localStorage.validTill
    };
}

export {
    loginProcedureForLocalStorage,
    logoutProcedureForLocalStorage,
    isLoggedIn,
    getUserDataFromLocalStorage
}